<template>
  <!-- section start -->
  <!-- ================ -->
  <div class="section clearfix">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2 class="text-center" style="margin: 0">{{ title }}</h2>
          <div class="separator" />

          <!-- Tabs start -->
          <!-- ================ -->
          <div class="vertical hc-tabs">
            <!-- Nav tabs -->
            <ul class="nav nav-tabs" role="tablist">
              <li v-for="(item, index) in articles" :key="index" :class="item.isActive ? 'active' : ''">
                <a :href="'#tab' + index" role="tab" data-toggle="tab">
                  {{ locale == 'en' ? item.catalogLabelEn : item.catalogLabel }}
                </a>
              </li>
              <!--
              <li class="active"><a href="#tab1" role="tab" data-toggle="tab">
                <i class="fa fa-magic pr-10" /> {{ $t('body.catalog_summary') }}</a>
              </li>
              <li><a href="#tab2" role="tab" data-toggle="tab">
                <i class="fa fa-list pr-10" /> {{ $t('body.catalog_equipment') }}</a>
              </li>
              <li><a href="#tab3" role="tab" data-toggle="tab">
                <i class="fa fa-check-square-o pr-10" /> {{ $t('body.catalog_process') }}</a>
              </li>
              <li><a href="#tab4" role="tab" data-toggle="tab">
                <i class="fa fa-cubes pr-10" /> {{ $t('body.catalog_materials') }}</a>
              </li>
              <li><a href="#tab5" role="tab" data-toggle="tab">
                <i class="fa fa-dollar pr-10" /> {{ $t('body.catalog_cost_cycle') }}</a>
              </li>
              -->
            </ul>

            <!-- Tab panes -->
            <div class="tab-content">
              <div v-for="(item, index) in articles" :id="'tab'+index" :key="index" :class="item.isActive ? 'tab-pane fade in active' : 'tab-pane fade'">
                <h3 class="text-center title">{{ locale == 'en' ? item.catalogLabelEn : item.catalogLabel }}</h3>
                <div class="space" />
                <div class="row">
                  <div v-if="locale === 'en'" class="col-md-12" v-html="item.contentEn" />
                  <div v-else class="col-md-12" v-html="item.content" />
                </div>
              </div>
              <!--
              <div id="tab1" class="tab-pane fade in active">
                <h3 class="text-center title">{{ $t('body.catalog_summary') }}</h3>
                <div class="space" />
                <div class="row">
                  <div v-if="locale === 'en'" class="col-md-12" v-html="content.en.summary" />
                  <div v-else class="col-md-12" v-html="content.zh.summary" />
                </div>
              </div>
              <div id="tab2" class="tab-pane fade">
                <h3 class="text-center title">{{ $t('body.catalog_equipment') }}</h3>
                <div class="space" />
                <div class="row">
                  <div v-if="locale === 'en'" class="col-md-12" v-html="content.en.equipment" />
                  <div v-else class="col-md-12" v-html="content.zh.equipment" />
                </div>
              </div>
              <div id="tab3" class="tab-pane fade">
                <h3 class="text-center title">{{ $t('body.catalog_process') }}</h3>
                <div class="space" />
                <div class="row">
                  <div v-if="locale === 'en'" class="col-md-12" v-html="content.en.process" />
                  <div v-else class="col-md-12" v-html="content.zh.process" />
                </div>
              </div>
              <div id="tab4" class="tab-pane fade">
                <h3 class="text-center title">{{ $t('body.catalog_materials') }}</h3>
                <div class="space" />
                <div class="row">
                  <div v-if="locale === 'en'" class="col-md-12" v-html="content.en.materials" />
                  <div v-else class="col-md-12" v-html="content.zh.materials" />
                </div>
              </div>
              <div id="tab5" class="tab-pane fade">
                <h3 class="text-center title">{{ $t('body.catalog_cost_cycle') }}</h3>
                <div class="space" />
                <div class="row">
                  <div v-if="locale === 'en'" class="col-md-12" v-html="content.en.cost_cycle" />
                  <div v-else class="col-md-12" v-html="content.zh.cost_cycle" />
                </div>
              </div>
              -->
            </div>
          </div>
          <!-- tabs end -->

        </div>
      </div>
    </div>
  </div>
  <!-- section end -->
</template>

<script>
// import _ from 'lodash'
import { mapGetters } from 'vuex'
import { getArticles } from '@/api/csp'
export default {
  name: 'CertificateGuide',
  props: {
    title: {
      required: true,
      type: String
    },
    type: {
      required: true,
      type: String
    },
    codeType: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      articles: []
    }
  },
  computed: {
    ...mapGetters([
      'locale',
      'name',
      'sidebar',
      'avatar',
      'device'
    ])
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      getArticles({ type: this.type, codeType: this.codeType }).then(resp => {
        if (resp.status) {
          console.log(resp.data)
          this.articles = resp.data
          this.articles[0].isActive = true
          /*
          _.forEach(resp.data, (value) => {
            if (value.catalog === 'summary') {
              this.content.zh.summary = value.content
              this.content.en.summary = value.contentEn
            } else if (value.catalog === 'equipment') {
              this.content.zh.equipment = value.content
              this.content.en.equipment = value.contentEn
            } else if (value.catalog === 'process') {
              this.content.zh.process = value.content
              this.content.en.process = value.contentEn
            } else if (value.catalog === 'materials') {
              this.content.zh.materials = value.content
              this.content.en.materials = value.contentEn
            } else if (value.catalog === 'cost_cycle') {
              this.content.zh.cost_cycle = value.content
              this.content.en.cost_cycle = value.contentEn
            }
            console.log(value)
          })
          */
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
