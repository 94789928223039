import request from '@/utils/request'

export function getCodes(params) {
  return request({
    url: '/csp/codes',
    method: 'get',
    params: params
  })
}
export function submitContact(data) {
  return request({
    url: '/csp/contact',
    method: 'post',
    data: data
  })
}
export function getArticles(params) {
  return request({
    url: '/csp/articles',
    method: 'get',
    params: params
  })
}
export function getNews(params) {
  return request({
    url: '/csp/news',
    method: 'get',
    params: params
  })
}
export function getNewsDetail(id) {
  return request({
    url: `/csp/news-detail/${id}`,
    method: 'get'
  })
}
export function getAttachments() {
  return request({
    url: '/csp/attachments',
    method: 'get'
  })
}
export function searchProject(params) {
  return request({
    url: '/csp/project/individual',
    method: 'get',
    params: params
  })
}
